import React, { useState } from 'react';
import './App.css';

function App() {
  const [exchangeRate, setExchangeRate] = useState(859.84);
  const [storePrice, setStorePrice] = useState(545454);
  const [cashPayment, setCashPayment] = useState(170000);
  const [cardPayment, setCardPayment] = useState(399999);
  const [results, setResults] = useState({});

  const calculate = () => {
    const discount = storePrice * 0.05;
    const subtotal = storePrice - discount;
    const tax = subtotal * 0.1;
    const total = subtotal + tax;
    const cardFee = cardPayment * 0.016;
    const taxRefund = tax - (tax * 0.155);
    const finalAmount = total + cardFee - taxRefund;
    const customsTax = subtotal * 0.18;

    setResults({
      discount: discount.toFixed(2),
      subtotal: subtotal.toFixed(2),
      tax: tax.toFixed(2),
      total: total.toFixed(2),
      cardFee: cardFee.toFixed(2),
      taxRefund: taxRefund.toFixed(2),
      finalAmount: finalAmount.toFixed(2),
      customsTax: customsTax.toFixed(2),
    });
  };

  return (
    <div className="container">
      <h1>물건값 계산기</h1>
      <label htmlFor="exchangeRate">엔화 환율:</label>
      <input
        type="number"
        id="exchangeRate"
        value={exchangeRate}
        onChange={(e) => setExchangeRate(parseFloat(e.target.value))}
      />
      <br />
      <label htmlFor="storePrice">매장판매가 (엔):</label>
      <input
        type="number"
        id="storePrice"
        value={storePrice}
        onChange={(e) => setStorePrice(parseFloat(e.target.value))}
      />
      <br />
      <label htmlFor="cashPayment">현금 결제 금액 (엔):</label>
      <input
        type="number"
        id="cashPayment"
        value={cashPayment}
        onChange={(e) => setCashPayment(parseFloat(e.target.value))}
      />
      <br />
      <label htmlFor="cardPayment">카드 결제 금액 (엔):</label>
      <input
        type="number"
        id="cardPayment"
        value={cardPayment}
        onChange={(e) => setCardPayment(parseFloat(e.target.value))}
      />
      <br />
      <button onClick={calculate}>계산하기</button>
      <h2>결과</h2>
      <p>할인 금액: <span id="discount">{results.discount}</span></p>
      <p>소계: <span id="subtotal">{results.subtotal}</span></p>
      <p>부가세: <span id="tax">{results.tax}</span></p>
      <p>합계: <span id="total">{results.total}</span></p>
      <p>카드 결제 수수료: <span id="cardFee">{results.cardFee}</span></p>
      <p>텍스리펀 금액: <span id="taxRefund">{results.taxRefund}</span></p>
      <p>최종 금액: <span id="finalAmount">{results.finalAmount}</span></p>
      <p>관부가세: <span id="customsTax">{results.customsTax}</span></p>
    </div>
  );
}

export default App;
